import React from 'react';

export default class Apr extends React.Component {
  state = {
    apr: 0
  }

  componentDidMount() {
    fetch('https://api.hedgefi.app/json/apr-bhsol.json')
      .then(response => {
        return response.json();
      }).then(res => {
        const apr = res.apr.toFixed(2);
        this.setState({ apr });
      }).catch((e) => {
        console.log(e.message);
      });
  }

  render() {
    return (
        <>{Intl.NumberFormat("en-US").format(this.state.apr)}</>
    )
  }
}