import React from 'react';

export default class Mc extends React.Component {
  state = {
    mc: 0
  }

  componentDidMount() {
    fetch('https://api.hedgefi.app/json/token-bhsol.json')
      .then(response => {
        return response.json();
      }).then(res => {
        const data = res.data;
        const mc = (data.value*process.env.REACT_APP_TOTAL_SUPPLY).toFixed(0);
        this.setState({ mc });
      }).catch((e) => {
        console.log(e.message);
      });
  }

  render() {
    return (
        <>{Intl.NumberFormat("en-US").format(this.state.mc)}</>
    )
  }
}