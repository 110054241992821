import './App.css';
import Aum from './components/aum'
import Price from './components/price'
import Mc from './components/mc'
import Holders from './components/holders'
import Total from './components/total'
import Apr from './components/apr'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src="/assets/images/BabyHedgeMain.png" className="App-logo" alt="logo" />
        <div style={{minHeight: 96, display: 'flex', flexDirection: 'row', marginTop: -10}}>
                        <a href="https://x.com/Baby_Hedge" target="_blank" rel="noopener noreferrer" style={{marginRight: 13}}>
                           <svg width="30" height="30" viewBox="0 0 300 300" fill="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                              <path d="M178.57 127.15 290.27 0h-26.46l-97.03 110.38L89.34 0H0l117.13 166.93L0 300.25h26.46l102.4-116.59 81.8 116.59h89.34M36.01 19.54H76.66l187.13 262.13h-40.66" fill="#FFF"/>
                           </svg>
                        </a>
                        <a href="https://t.me/BabyHedgePortal" target="_blank" rel="noopener noreferrer" style={{marginRight: 13}}>
                        <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
                           <path d="M66.964 134.874s-32.08-10.062-51.344-16.002c-17.542-6.693-1.57-14.928 6.015-17.59 7.585-2.66 186.38-71.948 194.94-75.233 8.94-4.147 19.884-.35 14.767 18.656-4.416 20.407-30.166 142.874-33.827 158.812-3.66 15.937-18.447 6.844-18.447 6.844l-83.21-61.442z" fill="none" stroke="#FFF" stroke-width="10"/>
                           <path d="M92.412 201.62s4.295.56 8.83-3.702c4.536-4.26 26.303-25.603 26.303-25.603" fill="none" stroke="#FFF" stroke-width="10"/>
                           <path d="M66.985 134.887l28.922 14.082-3.488 52.65s-4.928.843-6.25-3.613c-1.323-4.455-19.185-63.12-19.185-63.12z" fill-rule="evenodd" stroke="#FFF" stroke-width="10" stroke-linejoin="bevel"/>
                           <path d="M66.985 134.887s127.637-77.45 120.09-71.138c-7.55 6.312-91.168 85.22-91.168 85.22z" fill-rule="evenodd" stroke="#FFF" stroke-width="9.67" stroke-linejoin="bevel"/>
                        </svg>                        
                        </a>
                        <a href="https://medium.com/@HedgeFi" target="_blank" rel="noopener noreferrer" style={{marginRight: 14}}>
                           <svg width="32" height="32" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M15 12A13 13 0 1015 38 13 13 0 1015 12zM35.5 13c-3.59 0-6.5 5.373-6.5 12 0 1.243.102 2.441.292 3.568.253 1.503.662 2.879 1.192 4.065.265.593.56 1.138.881 1.627.642.978 1.388 1.733 2.202 2.201C34.178 36.811 34.827 37 35.5 37s1.322-.189 1.933-.539c.814-.468 1.56-1.223 2.202-2.201.321-.489.616-1.034.881-1.627.53-1.185.939-2.562 1.192-4.065C41.898 27.441 42 26.243 42 25 42 18.373 39.09 13 35.5 13zM45.5 14c-.259 0-.509.173-.743.495-.157.214-.307.494-.448.833-.071.169-.14.353-.206.551-.133.395-.257.846-.37 1.343-.226.995-.409 2.181-.536 3.497-.063.658-.112 1.349-.146 2.065C43.017 23.499 43 24.241 43 25s.017 1.501.051 2.217c.033.716.082 1.407.146 2.065.127 1.316.31 2.501.536 3.497.113.498.237.948.37 1.343.066.198.135.382.206.551.142.339.292.619.448.833C44.991 35.827 45.241 36 45.5 36c1.381 0 2.5-4.925 2.5-11S46.881 14 45.5 14z" fill="#FFF"/>
                           </svg>  
                        </a>
                        <a href="https://docs.hedgefi.app" target="_blank" rel="noopener noreferrer">
                           <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M10.802 17.77a.703.703 0 1 1-.002 1.406.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502.028-.533.212-.947.493-1.107.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474 0-.342-.354-.477-.355-.477-.658-.315-1.669-.788-2.655-1.25-2.108-.987-4.497-2.105-5.546-2.655-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862 2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174" fill="#FFF"/>
                           </svg>  
                        </a>
        </div>
        <p style={{fontSize: 14, marginTop: -35}}>
         Contract:<br />{process.env.REACT_APP_CONTRACT}
        </p>
        <p style={{marginTop: 10}}>
        {process.env.REACT_APP_MAIN1} <br />{process.env.REACT_APP_MAIN2}
        </p>
        <a
          href={process.env.REACT_APP_BUY}
          target="_blank"
          rel="noopener noreferrer"
          style={{textDecoration: 'none'}}
        >
          <button class="btn">Buy on {process.env.REACT_APP_BUY_AMM}</button>
        </a>
        <p style={{fontSize: 16}}>
        #BabyHedge by HedgeFi project - <a href="https://hedgefi.app" style={{textDecoration: 'none', color: 'white'}} target="_blank" rel="noopener noreferrer">hedgefi.app</a>
        </p>
        <table class="greenTable" style={{marginBottom: 20, marginTop: 10}}>
        <thead>
        <tr>
        <th colspan="2"><center>#BabyHedge Dashboard</center></th>
        </tr>
        </thead>
        <tbody>
        <tr>
        <td>Holders</td>
        <td><Holders></Holders></td>
        </tr>
        <tr>
        <td>Total Supply</td>
        <td>{Intl.NumberFormat("en-US").format(process.env.REACT_APP_TOTAL_SUPPLY)}</td>
        </tr>
        <tr>
        <td>Price</td>
        <td>$<Price></Price></td>
        </tr>
        <tr>
        <td>Market Cap</td>
        <td>$<Mc></Mc></td>
        </tr>
        <tr>
        <td>Transfer Tax</td>
        <td>{process.env.REACT_APP_TAX}%</td>
        </tr>
        <tr>
        <td>Assets Under Management</td>
        <td>$<Aum></Aum></td>
        </tr>
        <tr>
        <td>Total Distributed</td>
        <td><Total></Total> SOL</td>
        </tr>
        <tr>
        <td>Payout Frequency</td>
        <td>{process.env.REACT_APP_FREQUENCY}</td>
        </tr>
        <tr>
        <td>Minimum Required For Revshare</td>
        <td>{process.env.REACT_APP_MINIMUM} #BabyHedge</td>
        </tr>
        <tr>
        <td>Holders APR (Last 7d)</td>
        <td><Apr></Apr>%</td>
        </tr>
        </tbody>
        </table>
        <img src="/assets/images/sonarwatch.png" alt="sonarwatch" style={{width: 150}} />
        <p style={{fontSize: 14, marginBottom: 60, marginTop: 5}}>
         <a href="https://sonar.watch/portfolio/7fz23Ad9YyPvgXb7JPHkRSth2ekj9pRLdnXVPkD9uPuG" style={{textDecoration: 'none', color: 'white', marginTop: 5}} target="_blank" rel="noopener noreferrer">A.U.M. sonar.watch/portfolio/7fz23Ad9YyPvgXb7J...</a>
        </p>
      </header>
    </div>
  );
}

export default App;
