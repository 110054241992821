import React from 'react';

export default class Total extends React.Component {
  state = {
    total: 0
  }

  componentDidMount() {
    fetch('https://api.hedgefi.app/json/total-bhsol.json')
      .then(response => {
        return response.json();
      }).then(res => {
        const total = res.total.toFixed(2);
        this.setState({ total });
      }).catch((e) => {
        console.log(e.message);
      });
  }

  render() {
    return (
        <>{Intl.NumberFormat("en-US").format(this.state.total)}</>
    )
  }
}