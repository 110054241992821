import React from 'react';

export default class Holders extends React.Component {
  state = {
    holders: 0
  }

  componentDidMount() {
    fetch('https://api.hedgefi.app/json/holders-bhsol.json')
      .then(response => {
        return response.json();
      }).then(res => {
        const holders = res.total;
        this.setState({ holders });
      }).catch((e) => {
        console.log(e.message);
      });
  }

  render() {
    return (
        <>{this.state.holders}</>
    )
  }
}