import React from 'react';

export default class Aum extends React.Component {
  state = {
    aum: 0
  }

  componentDidMount() {
    fetch('https://api.hedgefi.app/json/aum-bhsol.json')
      .then(response => {
        return response.json();
      }).then(res => {
        let obj = res.elements.find(o => o.platformId === 'orca');
        const aum = obj.value.toFixed(0);
        this.setState({ aum });
      }).catch((e) => {
        console.log(e.message);
      });
  }

  render() {
    return (
        <>{Intl.NumberFormat("en-US").format(this.state.aum)}</>
    )
  }
}